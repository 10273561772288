@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");

* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
    background-size: 100%;
}

:root {
    --color-body-background: #f4f6fb;
    --color-body-text: #000;
    --color-breadcrumb-title: #1f1f1f;
    --color-breadcrumb-active: #333333;
    --color-breadcrumb-not-active: #575A5D;
    --color-card-background: #f4f6fb;
    --color-card-text: #000;
    --color-table-background: #f4f6fb;
    --color-table-color: #000;
    --color-border: #cacbdb;
    --color-invert: 0%;
    --color-scheme: light;
}

[data-theme="dark"] {
    --color-body-background: #263238;
    --color-body-text: #bbc4cc;
    --color-breadcrumb-title: #bbc4cc;
    --color-breadcrumb-active: #bbc4cc;
    --color-breadcrumb-not-active: #6C757D;
    --color-card-background: #16191c;
    --color-card-text: #fff;
    --color-table-background: #263238;
    --color-table-color: #bbc4cc;
    --color-border: #3c3d40;
    --color-invert: 100%;
    --color-scheme: dark;
}

body {
    background-color: var(--color-body-background);
    color: var(--color-body-text);
    font-family: "Nunito Sans", sans-serif;
}

body::-webkit-scrollbar {
    display: none;
}

.bg {
    background-image: url("../public/assets/images/login.jpg");
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.a-divider:after {
    display: block;
    width: 100%;
    height: 44px;
    background-color: transparent;
    background: -webkit-linear-gradient(to right, #fff, rgba(255, 255, 255, 0), #fff);
    background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0), #fff);
    z-index: 1;
    content: "";
}

.a-divider.a-divider-break {
    text-align: center;
    position: relative;
    padding-top: 1px;
    margin-bottom: 14px;
    line-height: 0;
}

.a-divider.a-divider-break:after {
    content: "";
    width: 100%;
    background-color: transparent;
    display: block;
    height: 1px;
    border-top: 1px solid #e7e7e7;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    z-index: 1
}

.a-divider.a-divider-break h5 {
    line-height: 1;
    font-size: 13px;
    color: #767676;
    font-weight: 400;
    z-index: 2;
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 0 8px 0 8px;
}

.card {
    background: var(--color-card-background);
    color: var(--color-card-text);
}

.card div h6 {
    color: var(--color-body-text);
}

.form-control {
    background: var(--color-card-background);
    color: var(--color-card-text);
    border: 1px solid var(--color-border);
}

.form-control:focus {
    background: var(--color-card-background);
    color: var(--color-card-text);
}

.form-control:disabled,
.form-control[readonly] {
    background: var(--color-card-background);
    color: var(--color-card-text);
}

input:focus,
input.form-control:focus {
    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--color-card-background) inset !important;
    -webkit-text-fill-color: var(--color-card-text) !important;
}

.form-select {
    background: var(--color-card-background);
    color: var(--color-card-text);
    border: 1px solid var(--color-border);
}

.form-select:focus {
    background: var(--color-card-background);
    color: var(--color-card-text);
}

.form-floating select {
    background: var(--color-card-background);
    color: var(--color-card-text);
}

input[type="date"] {
    color-scheme: var(--color-scheme);
}

.card-dark {
    background: var(--color-table-background);
    color: var(--color-table-color);
}

.card-transparent {
    background: #fff;
    color: #000;
    box-shadow: none;
}

.blog-card {
    background: #f4f6fb;
    color: #000;
}

table thead tr th {
    color: var(--color-table-color);
}

table tbody tr td {
    color: var(--color-card-text);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    color: var(--color-card-text);
}

.dropdown .dropdown-toggle {
    color: var(--color-card-text);
}

.dropdown-toggle:hover {
    color: var(--color-card-text);
}

.dropdown-menu {
    background: var(--color-body-background);
}

.dropdown-menu li button {
    color: var(--color-card-text);
}

.dropdown-menu li button:hover {
    background-color: transparent;
    color: #ff9b44;
}

.offcanvas {
    background: var(--color-card-background);
}

.modal-content {
    background: var(--color-card-background);
}

.btn-close {
    color: var(--color-card-text);
    filter: invert(var(--color-invert));
}

.nav-tabs {
    border-bottom: 1px solid var(--color-border);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--color-card-text);
    background-color: var(--color-card-background);
    border-color: var(--color-border);
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: var(--color-border);
    isolation: isolate;
}

#paginationnumbers {
    justify-content: center;
    align-items: center;
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.65rem;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.1875rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.83125rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.29rem;
    border-bottom-left-radius: 0.29rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.29rem;
    border-bottom-right-radius: 0.29rem;
}

.pagination .page-link {
    margin: 0 0.28571rem;
    border-radius: 0.2rem !important;
    background: var(--color-card-background);
    color: var(--color-card-text);
}

.pagination .page-item.active .page-link {
    box-shadow: 0 0.313rem 0.8rem rgba(122, 123, 151, 0.5),
        0 0.126rem 0.225rem rgba(122, 123, 151, 0.3);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.65rem;
    border-bottom-left-radius: 0.65rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.65rem;
    border-bottom-right-radius: 0.65rem;
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #3c44b1;
    border-color: #3c44b1;
}

.page-item.disabled .page-link {
    color: #000;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #eeeff8;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    border: 1px solid var(--color-border);
}

.page-link:hover {
    z-index: 1;
    text-decoration: none;
    border-color: #ff9b44;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0.313rem 0.719rem rgba(122, 123, 151, 0.1),
        0 0.156rem 0.125rem rgba(122, 123, 151, 0.06);
}

.btn-group,
.btn-group-vertical {
    display: inline-block;
}

.btn-group .btn {
    float: left;
}

.button-page .btn-group {
    margin-right: 10rem;
}

.btn-group [class*=btn-],
.dropdown-split [class*=btn-] {
    margin-right: 0;
}

.cp {
    cursor: pointer;
}

.blogdetails-image {
    width: 100%;
    height: 500px;
}

.span-hide {
    opacity: 1;
}

.hoverable-dropdown:hover .span-hide {
    position: absolute;
    opacity: 0;
    transition: opacity .35s ease;
}

.span-show {
    position: absolute;
    opacity: 0;
    transition: opacity .35s ease;
}

.hoverable-dropdown:hover .span-show {
    opacity: 1;
}

#flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Styles for tablet view */
@media (max-width: 768px) {
    .b2b-sidebar {
        display: none;
    }

    .bg {
        background: none;
        background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
    }
}

/* Styles for mobile view */
@media (max-width: 480px) {
    .sidebars {
        display: none;
    }

    .bg {
        background: none;
        background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
    }
}

.page-title {
    color: var(--color-breadcrumb-title);
    font-weight: bold;
}

.breadcrumb-item .breadcrumb-active {
    color: var(--color-breadcrumb-active);
}

.breadcrumb-active {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: var(--color-breadcrumb-active);
    text-decoration: none;
}

.breadcrumb-not-active {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    color: var(--color-breadcrumb-not-active);
    text-decoration: none;
}

.bg-ff9b44 {
    background-color: #ff9b44;
}

.btn-ff9b44 {
    background-color: #ff9b44;
    border: 1px solid #ff9b44;
    color: var(--color-card-text);
    font-weight: normal;
}

.btn-ff9b44:hover {
    background-color: #ff9b44;
    border: 1px solid #ff9b44;
    color: var(--color-card-text);
}

.btn-55ce63 {
    background-color: #55ce63;
    border: 1px solid #55ce63;
    color: var(--color-card-text);
    font-weight: normal;
}

.btn-55ce63:hover {
    background-color: #55ce63;
    border: 1px solid #55ce63;
    color: var(--color-card-text);
}

.btn-1B9339 {
    background-color: #1B9339;
    border: 1px solid #1B9339;
    color: #fff;
}

.btn-1B9339:hover {
    background-color: #1B9339;
    border: 1px solid #1B9339;
    color: #fff;
}

.btn-152740 {
    background-color: #152740;
    border: 1px solid #152740;
    color: #fff;
}

.btn-152740:hover {
    background-color: #152740;
    border: 1px solid #152740;
    color: #fff;
}

.btn-outline-secondary:hover {
    background: none;
    color: #000;
}

.img-w-h {
    height: 90px;
    width: 90px;
}

.verticle-line {
    margin-top: 40px;
    height: 150px;
}

.verticle-line1 {
    height: 140px;
}

.verticle-line2 {
    height: 120px;
}

.verticle-line3 {
    height: 160px;
}

.verticle-line4 {
    height: 170px;
}

.mix-empty-img {
    height: 255px;
    width: 255px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.mix-empty-img-enlarge {
    transform: scale(3);
    z-index: 100;
    margin-bottom: 50px;
}

.mix-empty-img-enlar {
    z-index: 100;
    position: fixed;
    top: 34%;
    left: 47%;
    transform: translate(-50%, -50%) scale(3);
    transition: transform 0.3s ease;
}


.rotate-button {
    position: relative;
    width: auto;
    padding: 6px 11px;
    border-radius: 20px;
    display: none;
    border: none;
    position: fixed;
    right: 0;
    top: 14%;
    left: 90%;
    margin-right: 10px;
    z-index: 200;

}

.mix-empty-img-enlar~.rotate-button {
    display: block;
}


.verticle-line5 {
    height: 150px;
}

.ol-int {
    list-style-type: initial;
}

.btn-drop {
    background: none;
    border: none;
    outline: none;
    color: var(--color-card-text);
}

.route-float {
    float: right;
}

.bank-img {
    height: 40px;
    width: 40px;
    border-radius: 5px;
}

.category-img {
    height: 100px;
    width: 100px;
}

.promotion-img {
    height: 150px;
    width: 30%;
}

.blogs-card-h {
    height: 500px;
}

.blogs-card-end {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
}

.card-img-top {
    height: 250px;
}

.fs-30 {
    font-size: 30px;
}

.verticle-line5 {
    height: 100px;
}

.card-chats-h {
    height: 12rem;
}

.status-info h6 {
    color: #1f1f1f;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
}

.status-info h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
}

.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
}

/* ----------------------------- Navbar --------------------------------- */


.App {
    border: 0;
    height: 100%;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
}

a:link {
    text-decoration: unset;
}

.link {
    color: black;
}

.xx {
    color: black;
}

.select2,
.select {
    color: white;
}

.menu-side {
    background-color: white;
    color: black;
}

.select2:hover,
.select2:active,
.select2:visited {
    color: white;
}

.active-transition {
    background-color: rgba(0, 0, 0, 0.2);
    background-size: 200%;
    transition: 0.1s 0s linear;
}

.bg-left-navabr {
    background-color: #34444c;
}

.bg-top-navbar-light {
    background: linear-gradient(to right, #ff9b44 0%, #fc6075 100%);
    border-bottom: 2px solid transparent;
}

.bg-top-navbar-dark {
    background: #263238;
    border-color: #2e3840;
    border-bottom: 2px solid transparent;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.menu-title {
    color: #ebebeb;
    opacity: 1;
    padding: 5px 15px;
    white-space: nowrap;
    text-align: left;
}

.filter-invert {
    filter: invert(100%);
}

.gm-style-mtc {
    display: none;
}

.widget-card-1 {
    margin-top: 0px;
}

.widget-card-1 .card1-icon {
    top: -30px;
    border-radius: 5px;
}


.App-b2b {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: 60px 1fr;
    grid-auto-columns: 200px 1fr;
    grid-template-areas:
        'b2bNavigation b2bNavigation'
        'b2bSidebar b2bMain';
    overflow: hidden;
}

.b2b-navbar {
    grid-area: b2bNavigation;

}

.b2b-sidebar {
    grid-area: b2bSidebar;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.b2b-main {
    grid-area: b2bMain;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.b2b-main::-webkit-scrollbar {
    display: none;
}

.b2b-sidebar::-webkit-scrollbar {
    display: none;
}

.App-b2g {
    height: 100vh;
    width: 100vw;
}

.navbar-b2g {
    grid-area: navbar-b2g;
}

.sidebar-b2g {
    grid-area: sidebar-b2g;
    overflow-y: scroll;
    overflow-x: hidden;
}

.main-b2g {
    grid-area: main-b2g;
    overflow-y: scroll;
    overflow-x: scroll;
}

.btn.dropdown-toggle::after {
    display: none;
}

.compalin_button {
    background-color: #fe924b;
}


.gmnoprint {
    transform: scale(0.75);
    transform-origin: 100% 100%;
}

.ecowrap_logo {
    cursor: pointer;
}

.sidebarhome {
    overflow: auto;
    height: 60vh;
}

.sidebarsidebar {
    height: 90vh;
    overflow: auto;
    position: static;
}

.sidebarsidebar::-webkit-scrollbar {
    width: 1px;
}

.sidebar_text {
    font-size: 16px;
}


.main_page_map {
    width: 100%;
}

.nav_button svg {
    height: 30px;
    width: 30px;
}

.Card_table_data {
    display: none;
}

.table_table_data {
    display: block;
}

.B2GB {
    display: block;
}

.searchMap {
    width: 35%;
}

.list_Box {
    width: 75%;
}

.button_Box {
    margin-top: 3%;
}

.sidebarProfile {
    display: none;
}

@media (min-width:300px) and (max-width:500px) {
    .sidebar_text {
        font-size: 12px;
    }

    .geofence_map_text {
        font-size: 10px;
    }

    .btn-primary {
        font-size: 10px;
    }

    .heading_text {
        font-size: 10px;
    }

    .B2g_dashboard {
        font-size: 9px;
    }

    .breadcrumb-item {
        height: 5px;
    }

    .Add_button {
        font-size: 8px;
    }

    .table_data {
        font-size: 13px;
    }

    .search_button {
        display: none !important;
    }

    .table_data_text {
        font-size: 12px;
    }

    .Card_table_data {
        display: block;
        border: 1px solid black;
        border-radius: 2vw;
        padding: 1.5vw;
        padding-left: 3vw;
        width: 95%;
        margin: auto;
        margin-top: 1vh;
    }

    .Card_data {
        margin-top: 1vw;
        width: 50%;
    }

    .Card_srNo {
        font-size: 14px;
        font-weight: 700;
    }

    .Card_data_list {
        font-size: 14px;
        font-weight: 500;
    }

    .table_table_data {
        display: none;
    }

    .Card_button {
        width: 100%;
        margin-top: 5vw;
        margin-bottom: .5vw;
    }

    .Card {
        display: flex;
    }


    .B2GB {
        display: none;
    }

    .Card_number {
        font-size: 10px;
        font-weight: 700;
    }

    .complain_box {
        height: 55.61vh;
        overflow: auto;
        padding-bottom: 10vh;
    }

    .daily_collection {
        height: 55.61vh;
        overflow: auto;
        padding-bottom: 10vh;
    }

    .searchMap {
        width: 100%;
    }

    .heading_CollectionBox {
        font-size: 15px;
    }

    .list_CollectionBox {
        font-size: 9px;
    }

    .list_Box {
        width: 100%;
    }

    .button_Box {
        font-size: 10px;
        margin-top: 10px;
    }

    .home_search_button {
        font-size: 8px;
    }

    .input_select {
        width: 80%;
    }

    .navProfileLogo {
        display: none !important;
    }

    .sidebarProfile {
        display: block !important;
    }

}

.pagination_box {
    width: 50%;
    margin: auto;
}

.box_overflow {
    height: 70vh;
    overflow: auto;
}

.States_box {
    display: flex;
    justify-content: space-between;
}



.gm-fullscreen-control {
    width: 2% !important;
    height: 4% !important;
}


.main_box {
    height: 90vh;
    overflow: auto;
}

.map_box {
    width: 95%;
}

.w-5 {
    width: 7%;
}

.listData:hover {
    box-shadow: 0 0 6px rgb(108, 107, 107);
    border-radius: 2px;
    cursor: pointer;
}

.button_all {
    background-color: #34444c;
    border: #1c2428 1px solid;
    color: white;
}

.button_all:hover {
    background-color: #565e64;
    border: #1c2428 1px solid;
    color: white;
}

.Box_IsActive_one {
    border: 2px solid red;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: red;
}

.Box_IsActive {
    border: 2px solid green;
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: green;
}


.profile-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* background-color: #E2E5E6; */
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0.3, 0.3, 0.3, 0.5);
}

/* Profile Page CSS Start */
.profile {
    height: 80vh;
}

.profile-card {
    max-width: 800px;
}

.profile-details,
.profile-roles {
    flex: 1;
    margin: 0 10px;
}

.profile-details p,
.profile-roles p {
    font-size: 16px;
    line-height: 1.6;
    margin: 8px 0;
    color: #4a4a4a;
}

strong {
    color: #2b2b2b;
    font-weight: 600;
}

.profile-card .profile-roles {
    text-align: left;
}

@media (max-width: 768px) {
    .profile-card {
        flex-direction: column;
        text-align: left;
    }

    .profile-details,
    .profile-roles {
        margin: 10px 0;
    }
}

/* Profile Page CSS End */
/* .profile {
    background-image: linear-gradient(#E2E5E6, #F5F6F7);
    width: 100%;
} */


.dropdown_Box {
    z-index: 10;
}