.Navbar_logo {
    width: 160px;
}

.navbar {
    max-height: 30vh !important;
}

.navProfileLogo {
    display: flex;
}

@media (min-width:300px) and (max-width:500px) {
    .Navbar_logo {
        width: 100px;
    }
}