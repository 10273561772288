/* b2b */
.nav_b2b {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.nav_b2b_profile {
    width: 30%;
    display: flex;
    justify-content: end;
    font-size: 1vw;
}

.nav-item {
    font-size: 1vw !important;
}

.navbar_nav {
    display: flex !important;
    justify-content: center;
}

.nav_dark_mode {
    width: 5vw;
    height: 4vw;
}

.sidebarProfile {
    display: none;
}

.sidebarprofile_List {
    font-size: 13px !important;
    color: white !important;
    font-size: 14px !important;
    margin-left: -10px;
    cursor: pointer;
    display: flex;
    margin-top: 1vw;
}

.sidebar_heading {
    font-size: 14px !important;
}

@media (min-width:200px) and (max-width:500px) {
    .siz_nav {
        display: none;
    }

    .sidebarProfile {
        display: block;
    }

    .sidebar_heading {
        font-size: 13px !important;
    }

    .logo_company {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}

.sidebar_box {
    width: 200px;
    height: 86.5vh;
    overflow: auto;
}

.main_box {
    height: 86.5vh;
    overflow: auto;
}

.sidebar_box::-webkit-scrollbar {
    width: 1px;
}



/* b2b */

/* Dashboard */

.Properties_input {
    display: flex;
}

@media (min-width:200px) and (max-width:500px) {
    .Properties_input {
        display: block;
    }
}

/* Dashboard */